import React from "react";

import { Link } from "./link";

const SpeakerLinkWrapper = ({ item, title, children, className }) => {
  return item.bio?.bio ? (
    <Link to={`/speaker/${item.link}/`} title={title} className={className}>
      {children}
    </Link>
  ) : (
    <span className={className}>{children}</span>
  );
};

export default SpeakerLinkWrapper;
