import React from "react";

const BoxFilter = ({ items, filter, setFilter }) => {
  return (
    <div className="box-filter">
      <ul className="box-filter__list d-flex flex-wrap justify-content-center">
        {items.map((data, index) => (
          <li
            key={index}
            className={`box-filter__item${filter === data ? " box-filter__item--active" : ""}`}
          >
            <button onClick={() => setFilter(data)} className="box-filter__btn text-uppercase">
              {data}
            </button>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default BoxFilter;
