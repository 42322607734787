import React, { useState } from "react";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import classNames from "classnames";
import SpeakerLinkWrapper from "./speaker-link-wrapper";
import ClockIcon from "../icons/clock-thin.svg";
import ChampagneGlassIcon from "../icons/champagne-glass-thin.svg";
import MugHotIcon from "../icons/mug-hot-thin.svg";
import TrophyIcon from "../icons/trophy-thin.svg";
import UtensilsIcon from "../icons/utensils-thin.svg";
import MicrophoneIcon from "../icons/microphone-thin.svg";
import FileDownload from "../icons/file-download.svg";

const BoxProgramma = ({ data, links }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className={classNames("box-program", isOpen && "box-program--open")}>
      <div className="container-md">
        <div className="row">
          <div className="col-12 col-md-11">
            <div className="d-flex flex-column flex-md-row">
              {links ? (
                <div className="box-program__info d-flex flex-md-column justify-content-between justify-content-md-start align-items-center align-items-md-start mb-3 mb-md-0">
                  {links.presentazione && (
                    <a
                      href={links.presentazione}
                      className="d-inline-flex align-items-center gap-4 mb-4"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <span>Scarica presentazione</span>
                      <FileDownload className="icon" />
                    </a>
                  )}
                  {links.video && (
                    <a
                      href={links.video}
                      className="d-inline-flex align-items-center gap-4 mb-4"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <span>Scarica video</span>
                      <FileDownload className="icon" />
                    </a>
                  )}
                </div>
              ) : (
                <div className="box-program__info d-flex flex-md-column justify-content-between justify-content-md-start align-items-center align-items-md-start mb-3 mb-md-0">
                  {data.room && <div className="box-program__sala">{data.room}</div>}
                  {data.time && (
                    <div className="box-program__hour">
                      <ClockIcon className="ico-svg" />
                      {data.time}
                    </div>
                  )}
                </div>
              )}
              <div
                className={classNames("d-flex flex-fill", data.icon && "flex-column flex-sm-row")}
              >
                <div className={classNames("flex-fill", data.icon && " d-flex align-items-end")}>
                  {data.eventType && <div className="box-program__type">{data.eventType}</div>}
                  {data.eventTitle && (
                    <div className="box-program__title">
                      {data.typeDescription && (
                        <div className="box-program__type">{data.typeDescription}</div>
                      )}
                      {data.eventDescription ? (
                        <button
                          type="button"
                          data-text={data.eventTitle}
                          className="box-program__btn text-start"
                          onClick={() => setIsOpen(!isOpen)}
                        >
                          {data.eventTitle}
                        </button>
                      ) : (
                        <span>{data.eventTitle}</span>
                      )}
                    </div>
                  )}
                  {data.eventDescription?.childMarkdownRemark?.html && (
                    <div className="box-program__text">
                      <div
                        dangerouslySetInnerHTML={{
                          __html: data.eventDescription?.childMarkdownRemark?.html,
                        }}
                      ></div>
                    </div>
                  )}
                </div>
                <div className={classNames(data.icon && "d-flex align-items-center")}>
                  {data.icon ? (
                    <div className="box-program__icon text-end">
                      {data.icon.title === "glasses" && <ChampagneGlassIcon className="ico-svg" />}
                      {data.icon.title === "coffee" && <MugHotIcon className="ico-svg" />}
                      {data.icon.title === "trophy" && <TrophyIcon className="ico-svg" />}
                      {data.icon.title === "utensils" && <UtensilsIcon className="ico-svg" />}
                      {data.icon.title === "microphone" && <MicrophoneIcon className="ico-svg" />}
                    </div>
                  ) : (
                    <>
                      {data?.speaker && (
                        <div className="box-program__speaker text-end">
                          {data?.speaker?.map((item, i, res) => (
                            <div key={i} className={`d-flex flex-column${i === 1 ? " mt-4" : ""}`}>
                              <div className="box-program__speaker-role order-2 order-md-1">
                                {item.role}
                              </div>
                              <SpeakerLinkWrapper
                                item={item}
                                className="box-program__speaker-link order-1 order-md-2"
                              >
                                <div className="box-program__speaker-name text-uppercase">
                                  {item.fullName}
                                </div>
                                <GatsbyImage
                                  image={getImage(item.image)}
                                  alt={item.fullName}
                                  className={classNames(
                                    "box-program__speaker-img",
                                    i === 1 ? "box-program__speaker-img--2" : "",
                                    i < res.length - 1 ? "box-program__speaker-img--1" : ""
                                  )}
                                  loading="eager"
                                />
                              </SpeakerLinkWrapper>
                            </div>
                          ))}
                        </div>
                      )}
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BoxProgramma;
