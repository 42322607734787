import React, { lazy, Suspense } from "react";
import { graphql, useStaticQuery } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

import { Link } from "./link";

const Spline = lazy(() => import("@splinetool/react-spline"));

const BoxLocation = ({ animazione, locationTitle, locationText }) => {
  const { cosmos } = useStaticQuery(graphql`
    {
      cosmos: file(relativePath: { eq: "bg-cosmos.png" }) {
        childImageSharp {
          gatsbyImageData(width: 1920, layout: CONSTRAINED)
        }
      }
    }
  `);
  return (
    <section className="section">
      <div className="container-md">
        <div className="row">
          <div className="col-12">
            <div className="box-location">
              {animazione && (
                <Suspense>
                  <Spline scene={animazione} className="animation-circle" />
                </Suspense>
              )}
              <GatsbyImage
                image={getImage(cosmos)}
                alt="img background location"
                objectPosition="center bottom"
                className="box-location__bg-img"
              />
              <div className="box-location__text">
                <div className="row">
                  <div className="col-12 col-md-10 col-lg-8 col-xl-6">
                    <div>
                      <div
                        className="box-location__title text-uppercase"
                        dangerouslySetInnerHTML={{ __html: locationTitle }}
                      ></div>
                      <div
                        className="box-location__desc"
                        dangerouslySetInnerHTML={{ __html: locationText }}
                      ></div>
                      <Link to="/location/" className="btn-link">
                        <span className="btn-link__circle"></span>
                        <span className="btn-link__text">
                          Esplora la <span className="d-block bold">location</span>
                        </span>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default BoxLocation;
